import { BigNumberInBase } from '@injectivelabs/utils'
import { AssetHeaderType, MarketInfoHeaderType } from '@/types'

export enum TransactionsTableColumn {
  TxnHash = 'txn-hash',
  Status = 'status',
  Type = 'type',
  Block = 'block',
  Amount = 'amount',
  Time = 'time',
  TxHashAndType = 'tx-hash-and-type',
  StatusAndAmount = 'status-and-amount'
}

export enum StakedTableColumn {
  Validator = 'validator',
  Amount = 'amount',
  Reward = 'reward'
}

export enum RestakingTableColumn {
  SourceValidator = 'source-validator',
  DestinationValidator = 'destination-validator',
  Amount = 'amount',
  CompletitionTime = 'completition-time'
}

export enum UnstakingTableColumn {
  Validator = 'validator',
  Amount = 'amount',
  CompletitionTime = 'completition-time'
}

export enum Cw20BalancesTableColumn {
  Holding = 'holding',
  ContractAddress = 'contract-address',
  Balance = 'balance'
}

export enum TradeHistoryTableColumn {
  Time = 'time',
  TradeId = 'trade-id',
  Market = 'market',
  Type = 'type',
  Side = 'side',
  Address = 'address',
  Amount = 'amount'
}

export enum SmartContractsTableColumn {
  ContractNameOrType = 'contract-name-or-type',
  Address = 'address',
  TxHash = 'tx-hash',
  Creator = 'creator',
  Executed = 'executed',
  LastExecutedOrCreated = 'last-executed-or-created'
}

export enum WasmTableColumn {
  IdOrType = 'id-or-type',
  TxHash = 'tx-hash',
  Creator = 'creator',
  Version = 'version',
  Instances = 'instances',
  CreationDate = 'creation-date'
}

export enum BlockTableColumn {
  Height = 'height',
  Proposer = 'proposer',
  Transactions = 'transactions',
  Timestamp = 'timestamp',
  HeightOrTime = 'height-or-time',
  ProposerOrTxs = 'proposer-or-txs'
}

export enum AssetsSmartContractTableColumn {
  Token = 'token',
  ContractAddress = 'contract-address'
}

export enum AssetsDenomHoldersTableColumn {
  Address = 'address',
  Quantity = 'quantity',
  Percentage = 'percentage',
  Value = 'value'
}

export enum FundingRateTableColumn {
  Time = 'time',
  FundingInterval = 'funding-interval',
  FundingRate = 'funding-rate'
}

export enum CommunitySpendPoolTableColumn {
  Timestamp = 'timestamp',
  BlockHeight = 'block-height',
  From = 'from',
  To = 'to',
  Amount = 'amount'
}

export enum ContractTokenHoldersTableColumn {
  Address = 'address',
  Balance = 'balance'
}

export enum RealTimeFundingRateTableColumn {
  Markets = 'markets',
  NextFunding = 'next-funding',
  EstimatedFundingRate = 'estimated-funding-rate',
  HourlyInterestRate = 'hourly-interest-rate'
}

export enum TradingRulesTableColumn {
  Markets = 'markets',
  BaseMakerFee = 'base-maker-fee',
  BaseTakerFee = 'base-taker-fee',
  MinAmountMovement = 'min-amount-movement',
  MinPriceMovement = 'min-price-movement',
  InitialMarginRatio = 'initial-margin-ratio',
  MaintenanceMarginRatio = 'maintenance-margin-ratio',
  MinNotional = 'min-notional'
}

export enum MarketsTableColumn {
  Markets = MarketInfoHeaderType.Markets,
  Type = MarketInfoHeaderType.Type,
  Price = MarketInfoHeaderType.Price,
  Volume = MarketInfoHeaderType.Volume,
  VolumeInUsd = MarketInfoHeaderType.VolumeInUsd
}

export enum AssetsTableColumn {
  Token = AssetHeaderType.Token,
  Price = AssetHeaderType.Price,
  CurrentSupply = AssetHeaderType.CurrentSupply,
  TotalUsdValue = AssetHeaderType.TotalUsdValue
}

export enum AccountBalanceTableColumn {
  Holding = 'holding',
  Price = 'price',
  AvailableBalance = 'available-balance',
  InUseReserved = 'in-use-reserved',
  UnrealizedPnl = 'unrealized-pnl',
  Amount = 'amount',
  UsdValue = 'usd-value'
}

export interface UTableColumn {
  key: string
  label?: string
  class?: string
}

export interface TransformedFundingRate {
  time: string
  formattedRate: string
  rateInBigNumber: BigNumberInBase
}
