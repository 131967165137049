export default {
  home: {
    stats: {
      injSupply: {
        marketCap: 'Market Cap',
        totalStaked: 'Current Total Staked',
        currentSupply: 'INJ Circulating Supply',
        tooltipContent: '100% of the INJ supply is now in circulation.'
      },
      transactions: {
        blockTime: 'Block Time',
        blockHeight: 'Block Height',
        totalTransactions: 'Total Transactions'
      },
      assets: {
        tvl: 'Total Asset Value',
        numberOfAssets: 'Number of Assets',
        numberOfWallets: 'Number of Wallets'
      },
      staked: {
        apr: 'Staking APR',
        total: 'Total Staked',
        injBurned: 'INJ Burned'
      }
    }
  }
}
