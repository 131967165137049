const _inherit = "inherit"
const _current = "currentColor"
const _transparent = "transparent"
const _black = "#14151A"
const _white = "#fff"
const _slate = {"50":"#f8fafc","100":"#f1f5f9","200":"#e2e8f0","300":"#cbd5e1","400":"#94a3b8","500":"#64748b","600":"#475569","700":"#334155","800":"#1e293b","900":"#0f172a","950":"#020617"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _zinc = {"50":"#fafafa","100":"#f4f4f5","200":"#e4e4e7","300":"#d4d4d8","400":"#a1a1aa","500":"#71717a","600":"#52525b","700":"#3f3f46","800":"#27272a","900":"#18181b","950":"#09090b"}
const _neutral = {"50":"#fafafa","100":"#f5f5f5","200":"#e5e5e5","300":"#d4d4d4","400":"#a3a3a3","500":"#737373","600":"#525252","700":"#404040","800":"#262626","900":"#171717","950":"#0a0a0a"}
const _stone = {"50":"#fafaf9","100":"#f5f5f4","200":"#e7e5e4","300":"#d6d3d1","400":"#a8a29e","500":"#78716c","600":"#57534e","700":"#44403c","800":"#292524","900":"#1c1917","950":"#0c0a09"}
const _red = {"50":"#fef2f2","100":"#FFA36E","200":"#fecaca","300":"#EB5844","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _orange = {"50":"#fff7ed","100":"#FF9150","200":"#fed7aa","300":"#fdba74","400":"#fb923c","500":"#f97316","600":"#ea580c","700":"#c2410c","800":"#9a3412","900":"#7c2d12","950":"#431407"}
const _amber = {"50":"#fffbeb","100":"#fef3c7","200":"#fde68a","300":"#fcd34d","400":"#fbbf24","500":"#f59e0b","600":"#d97706","700":"#b45309","800":"#92400e","900":"#78350f","950":"#451a03"}
const _yellow = {"50":"#fefce8","100":"#E8FF5F","200":"#fef08a","300":"#fde047","400":"#facc15","500":"#eab308","600":"#ca8a04","700":"#a16207","800":"#854d0e","900":"#713f12","950":"#422006"}
const _lime = {"50":"#FAFFFA","100":"#F6FFF5","200":"#EDFFEB","300":"#E4FFE0","400":"#D6FFD1","500":"#CEFFC8","600":"#81FF70","700":"#30FF14","800":"#15B800","900":"#0B6100","950":"#063300","DEFAULT":"#CEFFC8"}
const _green = {"50":"#f0fdf4","100":"#dcfce7","200":"#bbf7d0","300":"#B4FEA9","350":"#CEFFC8","400":"#4ade80","500":"#22c55e","600":"#16a34a","700":"#15803d","800":"#166534","900":"#14532d","950":"#052e16"}
const _emerald = {"50":"#ecfdf5","100":"#d1fae5","200":"#a7f3d0","300":"#6ee7b7","400":"#34d399","500":"#10b981","600":"#059669","700":"#047857","800":"#065f46","900":"#064e3b","950":"#022c22"}
const _teal = {"50":"#f0fdfa","100":"#ccfbf1","200":"#99f6e4","300":"#5eead4","400":"#2dd4bf","500":"#14b8a6","600":"#0d9488","700":"#0f766e","800":"#115e59","900":"#134e4a","950":"#042f2e"}
const _cyan = {"50":"#ecfeff","100":"#cffafe","200":"#a5f3fc","300":"#67e8f9","400":"#22d3ee","500":"#06b6d4","600":"#0891b2","700":"#0e7490","800":"#155e75","900":"#164e63","950":"#083344"}
const _sky = {"50":"#fcfdff","100":"#ebf0ff","200":"#d4e0ff","300":"#c2d3ff","400":"#aac2ff","500":"#87a7f3","600":"#6c91e7","700":"#4669b9","800":"#314887","900":"#182e4b","950":"#082f49"}
const _blue = {"50":"#eff6ff","100":"#dbeafe","200":"#E6E7EA","300":"#93c5fd","400":"#60a5fa","500":"#3b82f6","600":"#2563eb","700":"#1d4ed8","800":"#1e40af","900":"#1e3a8a","950":"#172554"}
const _indigo = {"50":"#eef2ff","100":"#e0e7ff","200":"#c7d2fe","300":"#a5b4fc","400":"#818cf8","500":"#6366f1","600":"#4f46e5","700":"#4338ca","800":"#3730a3","900":"#312e81","950":"#1e1b4b"}
const _violet = {"50":"#f5f3ff","100":"#ede9fe","200":"#ddd6fe","300":"#c4b5fd","400":"#a78bfa","500":"#8b5cf6","600":"#7c3aed","700":"#6d28d9","800":"#5b21b6","900":"#4c1d95","950":"#2e1065"}
const _purple = {"50":"#faf5ff","100":"#f3e8ff","200":"#e9d5ff","300":"#d8b4fe","400":"#4D3DFF","500":"#a855f7","600":"#9333ea","700":"#7e22ce","800":"#6b21a8","900":"#581c87","950":"#3b0764"}
const _fuchsia = {"50":"#fdf4ff","100":"#fae8ff","200":"#f5d0fe","300":"#f0abfc","400":"#e879f9","500":"#d946ef","600":"#c026d3","700":"#a21caf","800":"#86198f","900":"#701a75","950":"#4a044e"}
const _pink = {"50":"#fdf2f8","100":"#fce7f3","200":"#fbcfe8","300":"#f9a8d4","400":"#f472b6","500":"#ec4899","600":"#db2777","700":"#be185d","800":"#9d174d","900":"#831843","950":"#500724"}
const _rose = {"50":"#fff1f2","100":"#ffe4e6","200":"#fecdd3","300":"#fda4af","400":"#fb7185","500":"#f43f5e","600":"#e11d48","700":"#be123c","800":"#9f1239","900":"#881337","950":"#4c0519"}
const _uiBlack = "#000"
const _uiPrimary = {"100":"#EEEFFF","200":"#0E0D24","400":"#4D3DFF","700":"#060922"}
const _uiGray = {"200":"#E6E7EA","300":"#141729","400":"#B8B8BB","600":"#8A8A8C","700":"#727376","850":"#5C5C5E"}
const _ocean = {"50":"#E8E5FF","100":"#D5D1FF","200":"#A69EFF","300":"#7C70FF","400":"#4D3DFF","500":"#1F0AFF","600":"#1100D1","700":"#0D009E","800":"#09006B","900":"#040033","950":"#020019","DEFAULT":"#4D3DFF"}
const _midnight = {"50":"#E0E9F5","100":"#C1D3EB","200":"#83A8D7","300":"#467CC4","400":"#2D558B","500":"#182E4B","600":"#14263E","700":"#0F1C2E","800":"#0A131F","900":"#05090F","950":"#020508","DEFAULT":"#182E4B"}
const _lemon = {"50":"#FDFFF5","100":"#FCFFEB","200":"#F8FFD1","300":"#F4FFB3","400":"#EFFF94","500":"#E8FF5F","600":"#C7EB00","700":"#B2D100","800":"#93AD00","900":"#6C8000","950":"#4E5C00","DEFAULT":"#E8FF5F"}
const _forest = {"50":"#DFF7E1","100":"#BEEEC3","200":"#7DDE87","300":"#3CCD4B","400":"#248E2F","500":"#144E1A","600":"#103D14","700":"#0C3110","800":"#08200B","900":"#041005","950":"#020803","DEFAULT":"#144E1A"}
const _aubergine = {"50":"#F8DDEF","100":"#F2C0E1","200":"#E47CC1","300":"#D73DA3","400":"#A12176","500":"#611447","600":"#4C1038","700":"#3B0C2C","800":"#26081C","900":"#150410","950":"#080206","DEFAULT":"#611447"}
const _sand = {"50":"#FBF9F4","100":"#F6F2E9","200":"#EFE8D7","300":"#E6DCC1","400":"#DDCFAC","500":"#D5C498","600":"#C0A563","700":"#9C823F","800":"#69572B","900":"#332A15","950":"#19150A"}
const _cinnamon = {"50":"#FAEDE1","100":"#F5DAC2","200":"#E9B381","300":"#DF8F44","400":"#B7691F","500":"#7A4515","600":"#603710","700":"#4A2A0D","800":"#301B08","900":"#1A0F04","950":"#0D0702","DEFAULT":"#7A4515"}
const _coral = {"50":"#FFF5F0","100":"#FFECE0","200":"#FFDBC7","300":"#FFC8A8","400":"#FFB58A","500":"#FFA36E","600":"#FF7424","700":"#DB5000","800":"#943600","900":"#471A00","950":"#240D00","DEFAULT":"#FFA36E"}
const _turquoise = {"50":"#DCF6FE","100":"#B4EBFE","200":"#6DD9FD","300":"#22C5FC","400":"#039BCE","500":"#026585","600":"#025069","700":"#013D50","800":"#012A37","900":"#001319","950":"#000B0F","DEFAULT":"#026585"}
const _grey = {"100":"#f8f9fa","200":"#eeeff1","300":"#dbdde1","400":"#c5c8cd","500":"#a5abb6","600":"#7e838c","700":"#676c75","800":"#51545b","900":"#3b3e43"}
const _inj = {"sky":"#aac2ff","snow":"#eeefff","lime":"#ceffc8","sand":"#d5c498","lemon":"#e8ff5f","black":"#0b182b","coral":"#ffa36e","ocean":"#4d3dff","forest":"#144e1a","cinnamon":"#7a4515","midnight":"#182e4b","aubergine":"#611447","turquoise":"#026585"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "inherit": _inherit, "current": _current, "transparent": _transparent, "black": _black, "white": _white, "slate": _slate, "gray": _gray, "zinc": _zinc, "neutral": _neutral, "stone": _stone, "red": _red, "orange": _orange, "amber": _amber, "yellow": _yellow, "lime": _lime, "green": _green, "emerald": _emerald, "teal": _teal, "cyan": _cyan, "sky": _sky, "blue": _blue, "indigo": _indigo, "violet": _violet, "purple": _purple, "fuchsia": _fuchsia, "pink": _pink, "rose": _rose, "uiBlack": _uiBlack, "uiPrimary": _uiPrimary, "uiGray": _uiGray, "ocean": _ocean, "midnight": _midnight, "lemon": _lemon, "forest": _forest, "aubergine": _aubergine, "sand": _sand, "cinnamon": _cinnamon, "coral": _coral, "turquoise": _turquoise, "grey": _grey, "inj": _inj, "primary": _primary, "cool": _cool,  }
export { config as default, _inherit, _current, _transparent, _black, _white, _slate, _gray, _zinc, _neutral, _stone, _red, _orange, _amber, _yellow, _lime, _green, _emerald, _teal, _cyan, _sky, _blue, _indigo, _violet, _purple, _fuchsia, _pink, _rose, _uiBlack, _uiPrimary, _uiGray, _ocean, _midnight, _lemon, _forest, _aubergine, _sand, _cinnamon, _coral, _turquoise, _grey, _inj, _primary, _cool }