import { IS_DEVNET, IS_TESTNET } from '@shared/utils/constant'
import gitVersion from './gitVersion.json'
import devnetDenoms from '@/app/json/denoms/devnet.json'
import testnetDenoms from '@/app/json/denoms/testnet.json'
import mainnetDenoms from '@/app/json/denoms/mainnet.json'
import devnetTokens from '@/app/json/tokens/devnet.json'
import testnetTokens from '@/app/json/tokens/testnet.json'
import mainnetTokens from '@/app/json/tokens/mainnet.json'
import devnetValidators from '@/app/json/validators/devnet.json'
import testnetValidators from '@/app/json/validators/testnet.json'
import mainnetValidators from '@/app/json/validators/mainnet.json'
import devnetWasmQueries from '@/app/json/wasm/query/devnet.json'
import testnetWasmQueries from '@/app/json/wasm/query/testnet.json'
import mainnetWasmQueries from '@/app/json/wasm/query/mainnet.json'
import devnetWasmExecute from '@/app/json/wasm/execute/devnet.json'
import testnetWasmExecute from '@/app/json/wasm/execute/testnet.json'
import mainnetWasmExecute from '@/app/json/wasm/execute/mainnet.json'
import devnetSpotMarkets from '@/app/json/markets/spot/devnet.json'
import testnetSpotMarkets from '@/app/json/markets/spot/testnet.json'
import mainnetSpotMarkets from '@/app/json/markets/spot/mainnet.json'
import devnetDerivativeMarkets from '@/app/json/markets/derivative/devnet.json'
import testnetDerivativeMarkets from '@/app/json/markets/derivative/testnet.json'
import mainnetDerivativeMarkets from '@/app/json/markets/derivative/mainnet.json'

export const getDenoms = () => {
  if (IS_DEVNET) {
    return Object.keys(devnetDenoms)
  }

  if (IS_TESTNET) {
    return Object.keys(testnetDenoms)
  }

  return Object.keys(mainnetDenoms)
}

export const getTokens = () => {
  if (IS_DEVNET) {
    return devnetTokens
  }

  if (IS_TESTNET) {
    return testnetTokens
  }

  return mainnetTokens
}

export const getValidators = () => {
  if (IS_DEVNET) {
    return devnetValidators
  }

  if (IS_TESTNET) {
    return testnetValidators
  }

  return mainnetValidators
}

export const getCodeIdToQueryMessagesMap = () => {
  if (IS_DEVNET) {
    return devnetWasmQueries
  }

  if (IS_TESTNET) {
    return testnetWasmQueries
  }

  return mainnetWasmQueries
}

export const getCodeIdToExecuteMessagesMap = () => {
  if (IS_DEVNET) {
    return devnetWasmExecute
  }

  if (IS_TESTNET) {
    return testnetWasmExecute
  }

  return mainnetWasmExecute
}

export const getSpotMarkets = () => {
  if (IS_DEVNET) {
    return devnetSpotMarkets
  }

  if (IS_TESTNET) {
    return testnetSpotMarkets
  }

  return mainnetSpotMarkets
}

export const getDerivativeMarkets = () => {
  if (IS_DEVNET) {
    return devnetDerivativeMarkets
  }

  if (IS_TESTNET) {
    return testnetDerivativeMarkets
  }

  return mainnetDerivativeMarkets
}

export const gitBuild = () => {
  return (
    gitVersion || {
      branch: 'master',
      tag: 'v0.0.0',
      gitTagLink: '',
      logs: []
    }
  )
}

export const tokens = getTokens()
export const tradableDenoms = getDenoms()
export const validators = getValidators()
export const spotMarkets = getSpotMarkets()
export const derivativeMarkets = getDerivativeMarkets()
export const codeIdToQueryMessagesMap = getCodeIdToQueryMessagesMap()
export const codeIdToExecuteMessagesMap = getCodeIdToExecuteMessagesMap()
