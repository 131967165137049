export default defineAppConfig({
  ui: {
    primary: 'ocean',
    gray: 'slate',
    button: {
      font: 'font-normal',
      icon: {
        size: {
          md: 'h-4 w-4'
        }
      }
    },

    card: {
      background: 'dark:bg-midnight-700',
      ring: 'dark:ring-midnight-600'
    },

    tooltip: {
      base: 'p-2 h-auto whitespace-normal text-left',
      background: 'dark:bg-uiGray-300/90',
      color: 'text-white'
    },

    select: {
      base: 'dark:bg-midnight-700',
      color: {
        white: {
          outline:
            'dark:ring-midnight-600 dark:bg-midnight-700 dark:text-grey-500'
        }
      }
    },

    selectMenu: {
      container: 'z-50',
      shadow: 'shadow-md',
      ring: 'dark:ring-midnight-600',
      background: 'dark:bg-midnight-700',

      option: {
        selectedIcon: {
          base: 'w-3 h-3 text-uiPrimary-400 -mt-0.5'
        },
        active: 'dark:bg-midnight'
      },

      default: {
        selectedIcon: 'icomoon-free:checkmark'
      }
    },

    commandPalette: {
      input: {
        base: 'searchable-selector-input m-2 dark:text-uiGray-200 dark:placeholder:text-uiGray-600 border focus:border-solid dark:border-uiGray-600 rounded-lg h-auto',
        padding: 'px-3 py-2',
        icon: {
          size: 'h-4 w-4 dark:text-uiGray-600',
          padding: 'ps-7'
        }
      },
      emptyState: {
        wrapper: 'py-10 sm:px-10',
        queryLabel: 'dark:text-uiGray-600'
      },
      group: {
        command: {
          base: 'dark:text-uiPrimary-100 font-medium p-2',
          selectedIcon: {
            base: 'w-3 h-3 dark:text-uiPrimary-400'
          }
        }
      },
      default: {
        icon: 'ic:twotone-search',
        selectedIcon: 'icomoon-free:checkmark'
      }
    },

    table: {
      base: 'w-auto',
      divide: 'divide-y-0',
      thead: 'rounded-sm',
      tbody: 'dark:divide-midnight-600',
      th: {
        size: 'text-xs',
        font: 'font-normal',
        color: 'text-uiPrimary-100',
        padding: 'p-2 lg:px-4',
        base: 'whitespace-nowrap dark:bg-midnight-700 first:rounded-tl-1 first:rounded-bl-1 last:rounded-tr-1 last:rounded-br-1'
      },
      td: {
        size: 'text-xs',
        base: 'align-top',
        padding: 'p-4 max-lg:px-2',
        color: 'text-uiPrimary-100'
      },
      default: {
        sortButton: {
          class:
            'p-0 text-xs font-normal hover:bg-transparent [&>.iconify]:size-4 dark:text-uiPrimary-100 dark:hover:text-uiPrimary-100 dark:border-0'
        }
      }
    },

    popover: {
      background: 'dark:bg-midnight-700',
      ring: 'dark:ring-midnight-600'
    }
  }
})
