import { CommunitySpendPoolTableColumn } from '@/types'

export default {
  spendPool: {
    table: {
      [CommunitySpendPoolTableColumn.To]: 'To',
      [CommunitySpendPoolTableColumn.From]: 'From',
      [CommunitySpendPoolTableColumn.Amount]: 'Amount',
      [CommunitySpendPoolTableColumn.Timestamp]: 'Timestamp',
      [CommunitySpendPoolTableColumn.BlockHeight]: 'Block Height'
    },
    title: 'Community Spend Pool/Distribution Module',
    to: 'To CSP',
    from: 'From CSP/DM',
    enterAddress: 'Enter Address',
    timeRange: 'Select time range',
    communitySpendPool: 'Community Spend Pool',
    cspDistributionModule: 'CSP/Distribution Module',
    enterAddressToSeeResults: 'Please enter an Injective address to see results'
  }
}
